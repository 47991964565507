import AuthStore from "../auth";
import BusinessUserStore from "../businessUser/businessUser";
import CommonStore from "../common/common";
import CommonUtils from "../../utils/commonUtils";
import RootStore from "../root";
import {apiStatus} from "../../utils/constants";
import {BusinessUser} from "../../types/businessUser";
import {User} from "../../types/acctMgmt/user";
import {UserOption} from "../../types/acctMgmt/userOption";
import React from "react";
import {makeAutoObservable, runInAction} from "mobx";
import {updateBasicUserInfo, updateEmailPreferences, updateUserAccessLocations} from "../../clients/uasApi";
import {getOwnerLocations} from "../../clients/damBackendApi";
import {OwnerGroupResponse, OwnerLocation} from "../../types/owner-group";
import {NotificationMessages} from "../common/messaging/notificationMessages";


export default class ModifyUserStore {

    // Fields / Types
    editingUser: User = {} as User
    originalUser: User = {} as User

    currentModalTab: number = 0;
    locationSelection: string = "";
    ownerGroup: OwnerGroupResponse = {} as OwnerGroupResponse

    //Modal control
    isUserModalVisible: boolean = false;

    // Stores
    rootStore: RootStore;
    authStore: AuthStore;
    businessUserStore: BusinessUserStore;
    commonStore: CommonStore;

    //associated location variables
    associatedLocations: Array<OwnerLocation> = [];
    associatedLocationFilterValue: string = "";
    filteredAssociatedLocations: Array<OwnerLocation> = [];
    focusOnAssociatedModal: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.authStore = this.rootStore.authStore
        this.businessUserStore = this.rootStore.businessUserStore
        this.commonStore = this.rootStore.commonStore

        makeAutoObservable(this)
    };

    //Modify User Modal
    public openModifyUserModal = async (user: User) => {
        this.isUserModalVisible = true
        this.editingUser = CommonUtils.cloneObject(user);
        this.originalUser = user;
        this.currentModalTab = 0;
        await this.initializeUserLocations()
        this.commonStore.clearPageMsg()
        this.commonStore.clearFeatureMsg()
    }

    public updateUser = async (user: User) => {
        this.commonStore.processingApiCall(true);
        const compCode = this.rootStore.acctMgmtStore.compCode;
        CommonUtils.logNewRelicPageAction("Dealer Account Management", {
            userAction: 'Modify User',
            compCode: compCode,
            userId: user.userId
        });

        const updateBasicUserInfoCall = this.determineBasicUserInfoChanges()? updateBasicUserInfo(this.authStore.token, user, this.authStore.user?.email!) : null;
        const updateEmailPreferencesCall = this.determineEmailPreferencesChanges()? updateEmailPreferences(this.authStore.token, user.userId, user.userOptions, this.authStore.user?.email!) : null;
        const updateAccessLocationsCall = this.determineAssociatedLocationsChanges()? updateUserAccessLocations(this.authStore.token, user.userId, user.accessLocations, this.authStore.user?.email!) : null;

        const responses = await Promise.all([updateBasicUserInfoCall, updateEmailPreferencesCall, updateAccessLocationsCall].filter((call) => call !== null));
        const successResponses = responses.filter((resp: any) => resp?.status === apiStatus.success);

        if(successResponses.length === responses.length) {
            this.commonStore.displayFeatureNotification(`Success! Your updates for ${user.firstName} ${user.lastName} have been saved.`, "green");
        } else if(successResponses.length > 0 && successResponses.length < responses.length) {
            this.commonStore.displayFeatureNotification(NotificationMessages.UPDATE_SUCCESSFUL_PARTIAL, "red");
        } else {
            CommonUtils.displayConsoleLogError(`*** Error calling UAS to update users for comp code ${compCode}.  Response: ` + JSON.stringify(responses));
            this.commonStore.displayPageNotification(NotificationMessages.UPDATE_FAILED, "red")
        }

        this.commonStore.processingApiCall(false);
    };

    public resetUser = () => {
        this.editingUser = {} as User;
        this.originalUser = {} as User;
    }

    public handleModifyUserSave = () => {
        this.isUserModalVisible = false
        this.updateUser(this.editingUser).then(() => {
            this.rootStore.acctMgmtStore.getUsersByCompCode().then(() => undefined)
        })
        this.resetUser()
    }

    public handleModifyUserCancel = () => {
        this.isUserModalVisible = false
        this.resetUser()
    }

    public basicUserInfoFirstNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.editingUser.firstName = event.target.value
    }

    public basicUserInfoLastNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.editingUser.lastName = event.target.value
    }

    public basicUserInfoRoleHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.editingUser.role = event.target.value
    }

    public setCurrentModalTab(selectedTab: number) {
        this.currentModalTab = selectedTab;
    };

    public determineBasicUserInfoChanges() {
        return CommonUtils.fieldsChanged({firstName: this.editingUser.firstName, lastName: this.editingUser.lastName, role: this.editingUser.role},
            {firstName: this.originalUser.firstName, lastName: this.originalUser.lastName, role: this.originalUser.role})
    }

    public determineEmailPreferencesChanges(){
        return CommonUtils.fieldsChanged(this.editingUser.userOptions, this.originalUser.userOptions)
    }

    public determineUserChanges = (): boolean => {
        return (CommonUtils.fieldsChanged({...this.editingUser, accessLocations:undefined}, {...this.originalUser, accessLocations:undefined})
            || this.determineAssociatedLocationsChanges());
    };

    public determineAssociatedLocationsChanges() {
        return !CommonUtils.equalArray(this.editingUser.accessLocations, this.originalUser.accessLocations)
    }

    public onToggleEmailOption = (option: UserOption, checked: boolean) => {
        let optionToUpdate = this.editingUser?.userOptions.find(i => i.optionsId === option.optionsId)
        if (optionToUpdate) {
            optionToUpdate.value = checked
        }
    }

    public initializeUserLocations = async () => {
        this.associatedLocationFilterValue = ""
        this.associatedLocations = []
        this.filteredAssociatedLocations = []
        await this.getAssociatedLocations()
    }

    public searchLocations = () => {
        if (this.associatedLocationFilterValue.length > 0) {
            this.filteredAssociatedLocations = this.associatedLocations.filter((loc) => {
                return (
                    loc.selected
                    || loc.companyName.toLowerCase().includes(this.associatedLocationFilterValue.toLowerCase())
                    || loc.compCode.toLowerCase().includes(this.associatedLocationFilterValue.toLowerCase()))
            });
        } else {
            this.filteredAssociatedLocations = this.associatedLocations
        }
    }

    public getAssociatedLocations = async () => {
        let compCode = this.rootStore.acctMgmtStore.compCode;
        let parentSalesforceId = this.businessUserStore.businessUser?.parentSalesforceId;

        if (!parentSalesforceId) {
            return
        }

        this.rootStore.commonStore.processingApiCall(true);

        await getOwnerLocations(this.rootStore.authStore.token, parentSalesforceId).then((resp: any) => {
            switch (resp?.status) {
                case apiStatus.success :
                    runInAction(() => {
                        this.associatedLocations = resp.data.businessUsers.map((businessUser: BusinessUser) => {
                            return {
                                status: businessUser.active ? "Active" : "Inactive",
                                address: businessUser.address,
                                companyName: businessUser.companyName,
                                compCode: businessUser.compCode,
                                selected: this.editingUser.accessLocations.includes(businessUser.compCode)
                            }
                        }).filter((loc: OwnerLocation) => {
                            return loc.compCode !== compCode
                        });
                        this.filteredAssociatedLocations = this.associatedLocations;
                    })
                    break;
                case apiStatus.notFound :
                    break;
                case apiStatus.forbidden :
                    this.rootStore.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling BIS to get owner locations for parentSalesforceId ${parentSalesforceId}.  Response: ` + JSON.stringify(resp));
                    this.rootStore.commonStore.displayPageNotification(`Error occurred trying to get owner locations for comp code '${compCode}'.`, "red")
                    break;
            }
        });
        this.rootStore.commonStore.processingApiCall(false);
    };

    public onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.associatedLocationFilterValue = event.target.value
        this.searchLocations()
    }


    public isAllSelected = () => {
        return !(this.filteredAssociatedLocations.length === 0) && !(this.filteredAssociatedLocations.some((loc) => {
            return !loc.selected
        }))
    }

    public handleLocationSelectAll = () => {
        const allSelected: boolean = this.isAllSelected()
        this.editingUser.accessLocations = []

        this.filteredAssociatedLocations.forEach((location) => {
            if (allSelected) {
                location.selected = false
            } else {
                location.selected = true
                this.editingUser.accessLocations.push(location.compCode)
            }
        })
    }

    public handleLocationSelect = (location: OwnerLocation) => {
        if (location.selected) {
            location.selected = false
            this.editingUser.accessLocations.splice(this.editingUser.accessLocations.indexOf(location.compCode), 1)
        } else {
            location.selected = true
            this.editingUser.accessLocations.push(location.compCode)
        }
    }


}