import * as React from "react";
import carfaxLogo from "../../../images/logo.svg";
import iconLogout from "../../../images/icon_logout_white.svg";
import Store from "../../../stores/";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react";
import { withOktaAuth } from "@okta/okta-react";
import * as groups from "../../../types/groups";
import "./header.scss";
import UnsavedChangesModal, {UnsavedChangesProps} from "../../unsavedChanges/UnsavedChangesModal";
import { useHistory } from "react-router-dom";

export interface IHeaderProps {}

const profileMgmtStore = Store.profile.profileMgmt;
const commonStore = Store.common.common;

export default withOktaAuth( observer (
  class Header extends React.Component<{ oktaAuth: any }> {

  constructor(props: any) {
    super(props);
    this.logout = this.logout.bind(this);
  };

  async componentDidMount() {};

  async logout() {
    this.props.oktaAuth.signOut('/');
  };

  render() {
    return (
        <header id="header" className="dam-header">
          <div id="header-container-div" className="dam-container">
            <div id="header-container-logo-div"  className="dam-container-logo">
              <img src={carfaxLogo} alt="Logo" title="DEALER ACCOUNT MANAGEMENT" />
            </div>

            <CustomNavLink thisObj={this} path="/" id="custom-nav-link-to-home"
                           className="dam-container-nav-right hover-select" linkName="Home"/>

            <UnsavedModalForHeaderNavLink isVisible={commonStore.showUnsavedModalForHeaderNavigation}/>

            <NavLink to={"/profile"} id="nav-link-to-profile" className="dam-container-nav-profile hover-select" >Dealer Profile
            </NavLink>

            <CustomNavLink thisObj={this} path="/accountMgmt" id="custom-nav-link-to-account-mgmt"
                           className="dam-container-nav-account-mgmt hover-select" linkName="Account Management"/>

            <CustomNavLink thisObj={this} path="/reputationPartner" id="custom-nav-link-to-reputation-partner"
                           className="dam-container-nav hover-select" linkName="Reputation Partner"/>

            {/* SECURED LINK - Only Available to users in the 'DAM_Super_Admin' Okta group*/}
            {Store.auth.groups && Store.auth.groups.includes(groups.DAM_Super_Admin) &&
              <CustomNavLink thisObj={this} path="/partners" id="custom-nav-link-to-partners"
              className="dam-container-nav hover-select" linkName="Partner Maintenance"/>
            }

            {/* SECURED LINK - Only Available to users in the 'DAM_Super_Admin' and 'DAM_Admin' Okta groups*/}
            {Store.auth.groups && (Store.auth.groups.includes(groups.DAM_Super_Admin) || Store.auth.groups.includes(groups.DAM_Admin)) &&
              <CustomNavLink thisObj={this} path="/preferences" id="custom-nav-link-to-preferences"
                             className="dam-container-nav hover-select" linkName="Preferences Setup"/>
            }

            <div id="dam-container-nav-separator-line" className="dam-container-nav-separator-line hover-select" />

            <div id="header-container-logout-div" className="dam-container-nav hover-select">
              <a id="header-container-logout-a" href="#!" onClick={this.logout}>
                <img id="header-container-logout-img" className="icon-logo" src={iconLogout} alt="logout" /> Logout
              </a>
            </div>
          </div>
        </header>
    );
  };
}));

const CustomNavLink = observer((props: {thisObj:any, path: string, className:string, id:string, linkName: string}) =>{

  function handleClick(path: any, event: any) {
    if(window.location.href.indexOf("/profile") >= 0 && profileMgmtStore.didProfileDataChange()) {
      commonStore.showUnsavedModalForHeaderNavigation = true;
      commonStore.pathForHeaderNavigation = path;
      event.preventDefault();
    }
  }
  return (
        <NavLink to={props.path} onClick={handleClick.bind(props.thisObj, props.path)}
                   id={props.id}
                   className={props.className}>{props.linkName}</NavLink>
  );
});

const UnsavedModalForHeaderNavLink = observer((props:{isVisible: boolean}) => {
  let disableSave = false;
  if (!commonStore.isFormValid) {
    disableSave = true
  }

  const history = useHistory();

  const onClose = () => {
    commonStore.showUnsavedModalForHeaderNavigation = false
  }
  const onSave = async () => {
    commonStore.showUnsavedModalForHeaderNavigation = false;
    await profileMgmtStore.updateProfileData();
    history.push(commonStore.pathForHeaderNavigation);
  }
  const onContinue = () => {
    commonStore.showUnsavedModalForHeaderNavigation = false;
    profileMgmtStore.resetProfileOriginalData();
    history.push(commonStore.pathForHeaderNavigation);
  }

  const unsavedChangesProps: UnsavedChangesProps = {
    headerTitle: "You have unsaved updates!",
    isVisible : props.isVisible,
    onCloseMethod : onClose,
    onSaveMethod: onSave,
    onContinueWithoutSavingMethod: onContinue,
    disableSave: disableSave
  }
  return props.isVisible ? (
      <UnsavedChangesModal { ...unsavedChangesProps} />
  ) : null;
});
