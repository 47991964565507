import * as React from "react";
import { observer } from "mobx-react";
import { NoResults } from "../../shared-components/noResults/NoResults"
import {Notification, NotificationCloseButton, NotificationIcon, NotificationMessage} from "@carfax-stencils/notification";
import { RemovePartnerButton } from "src/components/reputationPartner/RemovePartnerButton";
import { SpinnerFox } from "@carfax-stencils/spinner";
import { withOktaAuth } from "@okta/okta-react";
import Breadcrumbs from "@carfax-stencils/breadcrumbs";
import Button from "@carfax-stencils/button";
import CheckboxInput from "@carfax-stencils/input-checkbox";
import Header from "../../shared-components/layout/header/Header";
import Page from "../../shared-components/layout/page/Page";
import SearchComponent from "../../shared-components/search/SearchComponent";
import ReputationPartnerHistoryModal, {ReputationPartnerHistoryProps} from "./ReputationPartnerHistoryModal";
import SelectInput from "@carfax-stencils/input-select"
import Store from "../../stores";
import "../../styles/main.scss";
import "../../shared-components/noResults/noResults.scss";
import "./ReputationPartner.scss";
import "../../shared-components/search/search.scss";
import "../../utils/newRelic/newrelic";

type ReputationPartnerProps = {
    oktaAuth: any;
    authState: any;
    match:any;
    emptyView?: JSX.Element;
}

// Stores
const rootStore = Store.root.root;
const businessUserStore = rootStore.businessUserStore;
const commonStore = rootStore.commonStore;
const searchStore = rootStore.searchStore;
const reputationPartnerStore = rootStore.reputationPartnerStore;
const reputationPartnerHistoryStore = rootStore.reputationPartnerHistoryStore;


export default withOktaAuth(

    class ReputationPartnerPage extends React.Component<ReputationPartnerProps> {

        async componentDidMount() {
            this.initializePage();
            this.setState({ready: true,});
        }

        initializePage = () => {
            commonStore.initializeStore();
            reputationPartnerStore.initializeStore();
            searchStore.initializeStore();
            const compCode = this.props.match.params.compCode
            if(compCode?.length > 0) {
                Store.search.search.handleCompCodeSearch(compCode);
            }
        };

        render() {
            return (
                <Page id="reputation-partner-page" title="Dealer Account Management | Reputation Partner">
                    <div id="reputation-partner-page-div">
                        <Header id="reputation-partner-page-header" />
                        <this.PageContent />
                    </div>
                </Page>
            );
        }

        PageContent = observer(() => {

            const hasData = businessUserStore.businessUser;

            return (
                <div id="reputation-partner-outer-div">
                    <div id="reputation-partner-breadcrumbs-div" className="breadcrumb-trail">
                        <Breadcrumbs
                            id="reputation-partner-breadcrumbs"
                            crumbs={[
                                { value: "Home", href: "#" },
                                { value: "Reputation Partner Tool" },
                            ]}
                        />
                    </div>
                    <div id="reputation-partner-page-heading-div" className="page__heading">
                        Reputation Partner Tool
                    </div>

                    {/*  COMMON PAGE-LEVEL NOTIFICATIONS  */}
                    {commonStore.pageMsg && (
                        <div id="reputation-partner-notification-div" className="page-notification-div">
                            <Notification
                                id="reputation-partner-notification"
                                role={"alert"}
                                scrollIntoView={true}
                                theme={commonStore.pageMsgTheme}>
                                <NotificationIcon/>
                                <NotificationMessage>
                                    {commonStore.pageMsg}
                                </NotificationMessage>
                                <NotificationCloseButton
                                    onClick={() => {commonStore.clearPageMsg()}}
                                />
                            </Notification>
                        </div>
                    )}

                    <div id="reputation-partner-layout-search-outer-div" className="reputation-partner-layout-wrapper">
                        <div id="reputation-partner-layout-seaerch-wrapper-div" className="reputation-partner-layout-search-wrapper-div">
                            <SearchComponent />
                        </div>
                        {hasData && this.renderData()}
                        {!hasData && this.renderNoData()}
                    </div>
                </div>
            );
        });

        renderNoData() {
            return (
                <div id="reputation-partner-layout-withData-outer-div" className="page__no-data">
                    <NoResults subtitle="Please use the search box on the left to view a specific reputation partner." />
                </div>
            )
        }

        renderData() {

            if(businessUserStore.businessUser?.compCode !== reputationPartnerStore.selectedCompCode) {
                reputationPartnerStore.isReputationPartnerAreaVisible = true;
                reputationPartnerStore.getAssociation(businessUserStore.businessUser?.compCode);
                reputationPartnerStore.handleAssociateChildLocationsChecked(false);
                reputationPartnerStore.getReputationPartners();
                reputationPartnerStore.selectedCompCode = businessUserStore.businessUser?.compCode;
            }

            return (
                <div id="reputation-partner-layout-body-outer-div" className="page__wrapper">

                    {/*  FEATURE-CONTENT NOTIFICATIONS  */}
                    {commonStore.featureMsg &&
                    <div id="reputation-partner-layout-body-message-div" className="reputation-partner-layout-message">
                        <Notification
                            id="reputation-partner-notification"
                            role={"alert"}
                            scrollIntoView={true}
                            theme={commonStore.featureMsgTheme}>
                            <NotificationIcon/>
                            <NotificationMessage>
                                {commonStore.featureMsg}
                            </NotificationMessage>
                            <NotificationCloseButton
                                onClick={() => {commonStore.clearFeatureMsg()}}
                            />
                        </Notification>
                    </div>
                    }
                    <this.ReputationPartnerModalComponent/>
                    <div id="reputation-partner-layout-withData-outer-div" className="page__data  reputation-partner-page-with-data">
                        <p id="reputation-partner-layout-table-heading-p" className="reputation-partner-layout-heading">
                            Reputation Partner Tool
                        </p>
                        <p id="reputation-partner-select-input-heading-p" className="reputation-partner-select-input-heading">
                            Select Reputation Partner to Add
                        </p>
                        <div id="reputation-partner-select-input-div" className="reputation-partner-select-input-div">
                            {reputationPartnerStore.processingApiCall &&
                            <div id="reputation-partner-spinner-div" className="reputation-partner-spinner-div">
                                <SpinnerFox id="reputation-partner-spinner" size={130} />
                            </div>
                            }
                            {!reputationPartnerStore.processingApiCall &&
                            <SelectInput
                                id="reputation-partner-select-input"
                                onChange={(option : any) => {
                                    reputationPartnerStore.handleSelectPartner(option.target.value);
                                }}
                                className={"add-location-selector"}
                                placeholder={"Select Partner"}
                                disabled={reputationPartnerStore.associationExists()}
                                value={reputationPartnerStore.selectedPartner?.managerName}
                            >
                                {
                                    reputationPartnerStore.selectedPartner?.managerName == null &&
                                    <option value="">Select Partner</option>
                                }
                                {reputationPartnerStore.managerNames.map((item: string, index: number) =>
                                    <option key={index} value={item}>
                                        {item}
                                    </option>      
                                )}
                            </SelectInput>
                            }
                        </div>
                        <div id="reputation-partner-checkbox-input-div" className="reputation-partner-checkbox-input-div">
                            <CheckboxInput
                                id="reputation-partner-associate-child-locations-checkbox"
                                checked={reputationPartnerStore.associateWithChildLocations}
                                onChange={(option: React.ChangeEvent<HTMLInputElement>) => {
                                    reputationPartnerStore.handleAssociateChildLocationsChecked(
                                        option.target.checked
                                    );
                                }}
                                label={reputationPartnerStore.determineAssociationLabel()}
                            />
                        </div>
                        <div id="reputation-partner-checkbox-note-div" className="reputation-partner-checkbox-note-div">
                            <p id="reputation-partner-checkbox-note" className="reputation-partner-checkbox-note">
                                * Any existing child associations will be overwritten.
                            </p>
                        </div>
                        <div id="reputation-partner-button-row-div" className="reputation-partner-button-row-div" >
                            <div id="reputation-partner-button-div" className="reputation-partner-button-div">
                                {!reputationPartnerStore.associationExists() && (
                                    <Button
                                        id="reputation-partner-add-remove-button"
                                        theme="blue"
                                        size="large"
                                        disabled={Object.keys(reputationPartnerStore.selectedPartner ?? {}).length === 0}
                                        onClick={reputationPartnerStore.addOrRemoveAssociation}
                                    >
                                        Add Partner
                                    </Button>
                                )}
                                {reputationPartnerStore.associationExists() && (
                                    <RemovePartnerButton theme="red" />
                                )}
                            </div>
                            <div id="reputation-partner-history-button-div" className="reputation-partner-button-div">
                                <Button
                                    id="reputation-partner-history-button"
                                    theme="blue-outline"
                                    size="large"
                                    children={`Audit History`}
                                    onClick={reputationPartnerHistoryStore.displayReputationPartnerHistoryModal}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        ReputationPartnerModalComponent = observer(() => {
            const reputationPartnerHistoryProps: ReputationPartnerHistoryProps = {
                isVisible: reputationPartnerHistoryStore.isModalVisible,
                store: reputationPartnerHistoryStore
            }
            return reputationPartnerHistoryProps.isVisible ? (
                <ReputationPartnerHistoryModal { ...reputationPartnerHistoryProps} />
            ) : null;
        });

    }

);
