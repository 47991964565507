import AuthStore from "../auth";
import BusinessUserStore from "../businessUser/businessUser";
import CommonStore from "../common/common";
import CommonUtils from "../../utils/commonUtils";
import RootStore from "../root";
import Validator from "../helpers/validator";
import {apiStatus} from "../../utils/constants";
import {
    ERROR_MSG_COMP_CODE_SEARCH_CRITERIA,
    ERROR_MSG_INVALID_PHONE_SEARCH_CRITERIA,
    ERROR_MSG_INVALID_EMAIL_SEARCH_CRITERIA,
    ERROR_MSG_PHONE_GREATER_THAN_TEN_DIGITS
} from "./messaging/errorMessagesSearch";
import {makeAutoObservable, runInAction} from "mobx";
import {SearchType} from "../../types/search/searchType";

import {
    getDealershipByCompCode,
    getDealershipByName,
    getDealershipByProfileSearch
} from "../../clients/damBackendApi";
import {getUserListBySearchValue} from "../../clients/uasApi";
import {Profile} from "../../types/features";


export default class SearchStore {

    // Variables
    processingApiCall: boolean = false;
    searchValue: string = "";
    selectedCategory: string = "";
    selectedCompCode: string = "";
    compCodeOnClick: string = "";
    invalidSearchCriteriaMessage: string = "";
    isValidSearchCriteria: boolean = true;

    // Stores
    rootStore: RootStore;
    authStore: AuthStore;
    businessUserStore: BusinessUserStore;
    commonStore: CommonStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.authStore = this.rootStore.authStore;
        this.businessUserStore = this.rootStore.businessUserStore;
        this.commonStore = this.rootStore.commonStore;
        makeAutoObservable(this);
    };

    public initializeStore = () => {
        this.selectedCategory = "Comp Code";
        this.businessUserStore.initializeStore();
        this.clearInvalidSearchCriteria();
    }

    public initializeSearch() {
        this.businessUserStore.businessUser = null;
        this.businessUserStore.businessUsersList = [];
        this.commonStore.clearPageMsg();
        this.commonStore.clearFormErrors();
        this.selectedCompCode = "";
        this.clearInvalidSearchCriteria();
    };

    public initializeSearchValue= () => {
           this.searchValue = "";
    }

    public compCodeChange = (compCode: string) => {
        this.selectedCompCode = compCode;
        this.businessUserStore.setBusinessUser(compCode);
        this.commonStore.clearPageMsg();
        this.commonStore.clearFeatureMsg();
        this.commonStore.clearFormErrors();
    };

    public clearInvalidSearchCriteria() {
        this.isValidSearchCriteria = true;
        this.invalidSearchCriteriaMessage = "";
    };

    determineCategoryInputLabel = () : string => {

        switch(this.selectedCategory) {
            case SearchType.CompCode: {
                return "Enter Comp Code:";
            }
            case SearchType.Name: {
                return "Enter Dealership / Service Shop Name:";
            }
            case SearchType.ProfileEmail: {
                return "Enter Email Address:";
            }
            case SearchType.TrackingPhone: {
                return "Enter Tracking Phone Number:";
            }
            case SearchType.WebsiteUrl: {
                return "Enter Website URL:";
            }
            case SearchType.UserEmail: {
                return "Enter User Email:"
            }
            case SearchType.UserName: {
                return "Enter User Name:"
            }
            case SearchType.UserPhone: {
                return "Enter User Phone Number:"
            }
            default: {
                return "Enter Search Criteria:";
            }
        };
    };

    determinePlaceholderText = () : string => {

        switch(this.selectedCategory) {
            case SearchType.CompCode: {
                return "Search by comp code ...";
            }
            case SearchType.Name: {
                return "Search by name ...";
            }
            case SearchType.ProfileEmail: {
                return "Search by email address ...";
            }
            case SearchType.TrackingPhone: {
                return "Search by tracking phone number ...";
            }
            case SearchType.WebsiteUrl: {
                return "Search by website URL ...";
            }
            case SearchType.UserEmail: {
                return "Search by user email address ...";
            }
            case SearchType.UserName: {
                return "Search by user name ...";
            }
            case SearchType.UserPhone: {
                return "Search by user phone number ...";
            }
            default: {
                return "Search by ...";
            }
        };
    };

    public handleSearchOptionSelect(option: string) {
        this.selectedCategory = option;
        this.searchValue = "";
        this.clearInvalidSearchCriteria();
    };

    public handleTextInput = (textInput: string) => {

        this.searchValue = textInput;
        const validateValue = textInput.trim()

        switch(this.selectedCategory) {
            case SearchType.CompCode: {
                if(Validator.containsSpecialCharacters(validateValue)) {
                    this.isValidSearchCriteria = false;
                    this.invalidSearchCriteriaMessage = ERROR_MSG_COMP_CODE_SEARCH_CRITERIA
                } else {
                    this.clearInvalidSearchCriteria();
                }
                break;
            }
            case SearchType.UserPhone: {
                if(isNaN(+validateValue)) {
                    this.isValidSearchCriteria = false;
                    this.invalidSearchCriteriaMessage = ERROR_MSG_INVALID_PHONE_SEARCH_CRITERIA
                }
                else if (validateValue.length > 10) {
                    this.isValidSearchCriteria = false;
                    this.invalidSearchCriteriaMessage = ERROR_MSG_PHONE_GREATER_THAN_TEN_DIGITS
                } else {
                    this.clearInvalidSearchCriteria();
                }
                break;
            }
            case SearchType.UserEmail: {
                if(validateValue.length > 0 && !Validator.isValidEmail(validateValue)) {
                    this.isValidSearchCriteria = false;
                    this.invalidSearchCriteriaMessage = ERROR_MSG_INVALID_EMAIL_SEARCH_CRITERIA
                }
                else {
                    this.clearInvalidSearchCriteria();
                }
                break;
            }
            case SearchType.ProfileEmail: {
                if(validateValue.length > 0 && !Validator.isValidEmail(validateValue)) {
                    this.isValidSearchCriteria = false;
                    this.invalidSearchCriteriaMessage = ERROR_MSG_INVALID_EMAIL_SEARCH_CRITERIA
                }
                else {
                    this.clearInvalidSearchCriteria();
                }
                break;
            }
        }
    };

    public handleKeyDown = (e: any) => {
        if (e.key === "Click") {
            this.handleEnterKey();
        }
        if (e.key === "Enter") {
            e.preventDefault();
            this.handleEnterKey();
        }
    };

    public handleEnterKey() {
        this.searchValue = this.searchValue.trim()
        if (this.searchValue.length > 0 && this.isValidSearchCriteria) {
            this.handleSearch().then(undefined);
        }
    };

    determineSearchBy() {
        let searchBy = "";
        switch(this.selectedCategory) {
            case SearchType.UserName: {
                searchBy = "user"
                break;
            }
            case SearchType.UserPhone: {
                searchBy = "phone"
                break;
            }
            case SearchType.UserEmail: {
                searchBy = "email"
                break;
            }
        }
        return searchBy;
    }

    public async handleSearch() {

        this.searchValue = this.searchValue.trim()
        if(this.isValidSearchCriteria) {
            switch(this.selectedCategory) {
                case SearchType.CompCode: {
                    await this.handleCompCodeSearch(this.searchValue);
                    break;
                }
                case SearchType.Name: {
                    await this.handleDealerShopNameSearch(this.searchValue);
                    break;
                }
                case SearchType.ProfileEmail:
                case SearchType.TrackingPhone:
                case SearchType.WebsiteUrl: {
                    await this.handleProfileSearch();
                    break;
                }
                case SearchType.UserEmail:
                case SearchType.UserName:
                case SearchType.UserPhone: {
                    await this.handleUasSearch(this.determineSearchBy(), this.searchValue);
                    break;
                }
            };
        }
    };

    protected processBusinessUserResults = (singleResult: boolean, respData: any, isUasSearch?: boolean) => {

        let compCode: string = "";

        if(singleResult) {
            this.businessUserStore.businessUsersList?.push(respData);
            compCode = this.businessUserStore.businessUsersList[0].compCode;
            this.compCodeChange(compCode);
        } else {
            if(isUasSearch) {
                this.businessUserStore.businessUsersList?.push(respData);
            }
            else {
                this.businessUserStore.businessUsersList = respData.businessUsers;
                if(this.businessUserStore.businessUsersList?.length === 1) {
                    compCode = this.businessUserStore.businessUsersList[0].compCode;
                    this.compCodeChange(compCode);
                }
            }
        }

        this.businessUserStore.handleDisplaySearchResults(true);
    };

    public async handleCompCodeSearch(compCode: string) {

        CommonUtils.logNewRelicPageAction("Comp Code Search", {userAction: `Search`, searchValue: compCode, searchType: this.selectedCategory})

        this.initializeSearch();
        this.processingApiCall = true;
        this.selectedCategory = SearchType.CompCode;
        this.searchValue = compCode.trim();

        this.getBisResultsByCompCode(this.searchValue, true);
    };

    public async handleDealerShopNameSearch(dealerShopName: string) {

        CommonUtils.logNewRelicPageAction("Profile Search", {userAction: `Search`, searchValue: dealerShopName, searchType: this.selectedCategory})

        this.initializeSearch();
        this.processingApiCall = true;

        getDealershipByName(this.authStore.token, dealerShopName).then((resp) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    runInAction(() => {
                        this.commonStore.clearFeatureMsg();
                        this.processBusinessUserResults(false, resp.data);
                    })
                    break;
                case 'NOT FOUND' :
                    runInAction(() => {
                        this.businessUserStore.handleDisplaySearchResults(false);
                        this.commonStore.displayPageNotification(`No data found for Dealership / Service Shop name  '${dealerShopName}'.`, "orange")
                    })
                    break;
                case apiStatus.forbidden :
                    runInAction(() => {
                        this.businessUserStore.handleDisplaySearchResults(false);
                        this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    })
                    break;
                default :
                    runInAction(() => {
                        this.businessUserStore.handleDisplaySearchResults(false);
                        this.commonStore.displayPageNotification(`Severe error occurred trying to find data for Dealership / Service Shop name '${dealerShopName}'.`, "red")
                    })
                    CommonUtils.displayConsoleLogError(`*** Error calling BIS to retrieve data for dealer name ${dealerShopName}.  Response: ` + JSON.stringify(resp));
                    console.log(`**! Error on search ${JSON.stringify(resp)}`)
                    break;
            };
            runInAction(() => {
                this.processingApiCall = false;
            })
        });
    };

    public async handleUasSearch(searchBy: string, searchValue: string) {
        this.initializeSearch();

        this.processingApiCall = true;

        CommonUtils.logNewRelicPageAction("User Account Search", {userAction: `Search`, searchValue: searchValue, searchType: this.selectedCategory})

        getUserListBySearchValue(this.authStore.token, searchBy, searchValue).then((resp: any) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    if(resp.data.length === 0) {
                        this.businessUserStore.handleDisplaySearchResults(false);
                        this.commonStore.displayPageNotification(`No results found for search criteria: '${searchValue}'.`, "orange")
                    } else {
                        if(resp.data.length === 1) {
                            this.getBisResultsByCompCode(resp.data[0], true, true);
                        }
                        else {
                            resp.data.forEach((result: any) => {
                                this.getBisResultsByCompCode(result, false, true);
                            })
                        }
                    }
                    break;
                case apiStatus.notFound :
                    runInAction(() => {
                        this.businessUserStore.handleDisplaySearchResults(false);
                        this.commonStore.displayPageNotification(`No results found for search criteria: '${searchValue}'.`, "orange")
                    })
                    break;
                case apiStatus.badRequest :
                    runInAction(() => {
                        this.businessUserStore.handleDisplaySearchResults(false);
                        this.commonStore.displayPageNotification(`Invalid search criteria: '${searchValue}'.`, "orange")
                    })
                    break;
                default :
                    runInAction(() => {
                        this.businessUserStore.handleDisplaySearchResults(false);
                        this.commonStore.displayPageNotification(`Severe error occurred trying to find user data for '${searchValue}'.`, "red")
                    })
                    CommonUtils.displayConsoleLogError(`*** Error calling UAS to retrieve user data for ${searchValue}.  Response: ` + JSON.stringify(resp));
                    break;
            };
            runInAction(() => {
                this.processingApiCall = false;
            })
        })
    };
    
    
    public async handleProfileSearch() {

        this.initializeSearch();
        let preparedSearchValue : string = this.prepareProfileSearchValue();
        this.processingApiCall = true;

        CommonUtils.logNewRelicPageAction("Profile Search", {userAction: `Search`, searchValue: preparedSearchValue, searchType: this.selectedCategory})

        getDealershipByProfileSearch(this.authStore.token, preparedSearchValue).then((resp: any) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    if(resp.data.length === 0 ) {
                        this.businessUserStore.handleDisplaySearchResults(false);
                        this.commonStore.displayPageNotification(`No profile data found for '${this.searchValue}'.`, "orange")
                    } else {
                        let response: any =
                            {
                                "businessUsers": resp.data
                            };
                        runInAction(() => {
                            this.commonStore.clearFeatureMsg();
                            this.processBusinessUserResults(false, response);
                        });
                    }
                    break;
                case 'NOT FOUND' :
                    runInAction(() => {
                        this.businessUserStore.handleDisplaySearchResults(false);
                        this.commonStore.displayPageNotification(`No profile data found for '${this.searchValue}'.`, "orange")
                    })
                    break;
                case apiStatus.forbidden :
                    runInAction(() => {
                        this.businessUserStore.handleDisplaySearchResults(false);
                        this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    })
                    break;
                default :
                    runInAction(() => {
                        this.businessUserStore.handleDisplaySearchResults(false);
                        this.commonStore.displayPageNotification(`Severe error occurred trying to find profile data for '${this.searchValue}'.`, "red")
                    })
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to retrieve profile data for ${this.searchValue}.  Response: ` + JSON.stringify(resp));
                    break;
            };
            runInAction(() => {
                this.processingApiCall = false;
            })
        });
    };

    public getBisResultsByCompCode(compCode: string, singleResult: boolean, isUasSearch?: boolean) {

        if(compCode) {
            getDealershipByCompCode(this.authStore.token, compCode?.trim().toUpperCase()).then((resp) => {
                switch(resp?.status){
                    case 'SUCCESS' :
                        runInAction(() => {
                            this.commonStore.clearFeatureMsg();
                            this.processBusinessUserResults(singleResult, resp.data, isUasSearch)
                        })
                        break;
                    case 'NOT FOUND' :
                        runInAction(() => {
                            this.businessUserStore.handleDisplaySearchResults(false);
                            if(singleResult) {
                                this.commonStore.displayPageNotification(`No data found for comp code '${compCode}'.`, "orange")
                            }
                        })
                        break;
                    case apiStatus.forbidden :
                        runInAction(() => {
                            this.businessUserStore.handleDisplaySearchResults(false);
                            this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                        })
                        break;
                    default :
                        runInAction(() => {
                            this.businessUserStore.handleDisplaySearchResults(false);
                            this.commonStore.displayPageNotification(`Severe error occurred trying to find data for comp code '${compCode}'.`, "red")
                        })
                        CommonUtils.displayConsoleLogError(`*** Error calling BIS to retrieve data for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                        break;
                };
                runInAction(() => {
                    this.processingApiCall = false;
                });
            });
        }
    }

    public prepareProfileSearchValue = () : string => {
        let formattedValue = "";

        switch(this.selectedCategory) {
            case SearchType.TrackingPhone: {
                formattedValue = CommonUtils.unFormatPhoneNumber(this.searchValue);
                break;
            }
            case SearchType.ProfileEmail:
            case SearchType.WebsiteUrl: {
                formattedValue = this.searchValue.trim();
                break;
            }
        };

        return formattedValue;
    };

    public setCompCodeOnClick(compCode: string) {
        this.compCodeOnClick = compCode;
    }

    public onCompCodeClick = (businessUser: any) => {
        if(this.commonStore.currentFeature === Profile) {
            this.rootStore.profileMgmtStore.onCompCodeClick(businessUser);
        }
        else {
            this.compCodeChange(businessUser.compCode);
        }
    };
};
