import DamAxiosClient from "./DamAxiosClient";
import UrlBuilder from "../utils/urlBuilder";
import {User} from "../types/acctMgmt/user";
import {LegacyUser} from "../types/acctMgmt/legacyUser";
import {getInviteTemplateKey} from "../types/acctMgmt/inviteUserTypes";
import {UserOption} from "../types/acctMgmt/userOption";

const API_URI = UrlBuilder.buildBaseUrl("/uas")
const damAxiosClient: DamAxiosClient = new DamAxiosClient()

export const createLegacyUser = async(token: string, compCode: string, name: string, createdByUserEmail: string) : Promise<any> => {
    const path : string = API_URI + `/cname-user/create`
    const params: any = {legacy:true}

    let data: any =  JSON.stringify({
        compCode: compCode,
        name: name,
        createdBy: createdByUserEmail
    })
    return damAxiosClient.post(path, params, token, data );
}

export const editLegacyUser = async(token:string, legacyUser: LegacyUser, updatedByUserEmail: string) : Promise<any> => {
    const path : string = API_URI + `/cname-user/update`
    const params: any = {legacy:true}

    let data: any =  JSON.stringify({
        username: legacyUser.username,
        name: legacyUser.name,
        password: legacyUser.password,
        status: legacyUser.status,
        modifiedBy: updatedByUserEmail
    })

    return damAxiosClient.post(path, params, token, data)
}

export const getLegacyUserListByCompCode = async(token: string, compCode: string) : Promise<any> => {
    const path : string = API_URI + `/cname-user/list/${compCode}`
    const params :any = {legacy:true}
    return damAxiosClient.get(path, params, token );
};

export const getLegacyUsersAuthStatus = async(token: string, compCode: string) : Promise<any> => {
    const path : string = API_URI + `/subscriber/cnameusers/${compCode}/auth`
    return damAxiosClient.get(path, null, token );
};

export const getUserAuditHistory = async(token: string, userId: number) : Promise<any> => {
    const path : string = API_URI + `/user/history/${userId}?core=true`
    return damAxiosClient.get(path, null, token );
};

export const getUserListByCompCode = async(token: string, compCode: string,itemsPerPage: number, pageToRetrieve: number) : Promise<any> => {
    const path : string = API_URI + `/user/list/${compCode}?core=true&itemsPerPage=${itemsPerPage}&pageToRetrieve=${pageToRetrieve}`
    return damAxiosClient.get(path, null, token );
};

export const getUserListBySearchValue = async(token: string, searchType: string, searchValue: string) : Promise<any> => {
    const path : string = API_URI + `/user/search-by/${searchType}?core=true&searchValue=${searchValue.trim()}`
    return damAxiosClient.get(path, null, token );
};

export const reactivateUser = async(token: string, updateByUserEmail: string, userId: number) : Promise<any> => {
    const path : string = API_URI + `/user/reactivate`
    const params : any = {core: true}
    const body : any = JSON.stringify({
        userId: userId, 
        updatedByUser: updateByUserEmail,
        source: "dam-aws"
    })
    return damAxiosClient.post(path, params, token, body);
};

export const deactivateUser = async(token: string, updateByUserEmail: string, userId: number) : Promise<any> => {
    const path : string = API_URI + `/user/deactivate`
    const params : any = {core: true}
    const body : any = JSON.stringify({
        userId: userId.toString(), 
        updatedByUser: updateByUserEmail,
        source: "dam-aws"
    })
    return damAxiosClient.post(path, params, token, body);
};

export const updateBasicUserInfo = async(token: string, user: User, updatedByUser: string) : Promise<any> => {
    const path : string = API_URI + `/user/update`
    const params : any = {core: true}

    let data: any =  JSON.stringify({
        userId: user.userId,
        compCode: user.compCode,
        firstName: user.firstName,
        lastName: user.lastName,
        roleName: user.role,
        source: "dam-aws",
        updatedByUser: updatedByUser
    })

    return damAxiosClient.post(path, params, token, data );
};

export const updateEmailPreferences = async(token: string, userId: number, userOptions: Array<UserOption>, updatedByUser: string) : Promise<any> => {
    const path : string = API_URI + `/user/options/update`
    const params : any = {core: true}

    let data: any =  JSON.stringify({
        userId: userId,
        userOptions: userOptions,
        source: "dam-aws",
        updatedByUser: updatedByUser
    })

    return damAxiosClient.post(path, params, token, data );
};

export const updateUserAccessLocations = async(token: string, userId: number, accessLocations: Array<string>, updatedByUser: string) : Promise<any> => {
    const path : string = API_URI + `/user/accessLocations`
    const params : any = {core: true}

    let data: any =  JSON.stringify({
        userId: userId,
        accessLocations: accessLocations,
        source: "dam-aws",
        updatedByUser: updatedByUser
    })

    return damAxiosClient.post(path, params, token, data );
};

export const updateLegacyUsersAuthStatus = async(token: string, compCode: string) : Promise<any> => {
    const path : string = API_URI + `/subscriber/cnameusers/${compCode}/auth`
    const params : any = {source:'dam-aws'}
    return damAxiosClient.post(path, params, token, null );
};

export const suspendAll = async(token: string, updateByUserEmail: string, compCode: string) : Promise<any> => {
    const path : string = API_URI + `/user/suspend-all`
    const params : any = {core: true}
    const body : any = JSON.stringify({
        compCode: compCode, 
        updatedByUser: updateByUserEmail,
        source: "dam-aws"
    })
    return damAxiosClient.post(path, params, token, body );
}

export const suspendUser = async(token: string, updateByUserEmail: string, userId: number) : Promise<any> => {
    const path : string = API_URI + `/user/suspend`
    const params : any = {core: true}
    const body : any = JSON.stringify({
        userId: userId.toString(), 
        updatedByUser: updateByUserEmail,
        source: "dam-aws"
    })
    return damAxiosClient.post(path, params, token, body );
}

export const unsuspendUser = async(token: string, updateByUserEmail: string, userId: number) : Promise<any> => {
    const path : string = API_URI + `/user/unsuspend`
    const params : any = {core: true}
    const body : any = JSON.stringify({
        userId: userId.toString(), 
        updatedByUser: updateByUserEmail,
        source: "dam-aws"
    })
    return damAxiosClient.post(path, params, token, body );
}

export const unsuspendAll = async(token: string, updateByUserEmail: string, compCode: string) : Promise<any> => {
    const path : string = API_URI + `/user/unsuspend-all`
    const params : any = {core: true}
    const body : any = JSON.stringify({
        compCode: compCode, 
        updatedByUser: updateByUserEmail,
        source: "dam-aws"
    })
    return damAxiosClient.post(path, params, token, body );
}
export const inviteUser = async(token: string, user: User, inviteTemplate: string, updateByUserEmail: String) : Promise<any> => {
    const inviteTemplateCode = getInviteTemplateKey(inviteTemplate)
    const path : string = API_URI + "/invite"
    const params : any = {core: true}

    let data: any =  JSON.stringify({
        compCode: user.compCode,
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.email,
        role: user.role,
        template: inviteTemplateCode,
        accessLocations: user.accessLocations,
        source: "dam-aws",
        updatedByUser: updateByUserEmail
    })

    return damAxiosClient.post(path, params, token, data );
};

export const resendInvite = async(token: string, user: User, updateByUserEmail: String) : Promise<any> => {

    const path : string = API_URI + `/invite/reinvite`;
    const params : any = {core: true}

    let data: any =  JSON.stringify({
        userId: user.userId,
        updatedByUser: updateByUserEmail,
        source: "dam-aws"
    })

    return damAxiosClient.post(path, params, token, data );
};

export const moveUsers = async(token: string, userIds: Array<number>, toCompCode: string, updatedByUser: string) : Promise<any> => {
    const path : string = API_URI + `/user/move-users`
    const params : any = {core: true}

    if(userIds?.length <= 0){
        return
    }

    let data: any =  JSON.stringify({
        userIds,
        updatedByUser,
        toCompCode,
        source: "dam-aws"
    })

    return damAxiosClient.post(path, params, token, data );
};