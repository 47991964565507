import {AxiosError, AxiosResponse} from "axios";
import ApiStatus from "../types/index";
import {
    apiStatus,
    HTTP_STATUS_400_MESSAGE, HTTP_STATUS_401_MESSAGE,
    HTTP_STATUS_403_MESSAGE, HTTP_STATUS_404_MESSAGE,
    HTTP_STATUS_405_MESSAGE, HTTP_STATUS_409_MESSAGE,
    HTTP_STATUS_422_MESSAGE, HTTP_STATUS_500_MESSAGE
} from "../utils/constants";
import {DamAxiosClientResponse} from "../types/api/DamAxiosClientResponse";

export interface IDamAxiosClientResponse {
    data: DamAxiosClientResponse | null;
    status: ApiStatus.ApiStatus;
    error: AxiosError
    damErrorCode: number
    damDefaultErrorMessage: string
}

const axios = require('axios');

export default class DamAxiosClient {

    public get(path: string, params: any, authToken: string) : Promise<IDamAxiosClientResponse> {
        return axios.get(path, this.getConfig(params, authToken))
            .then(this.handleSuccess)
            .catch(this.handleErrors)
    }

    public post(path: string, params: any, authToken: string, data: any) : Promise<IDamAxiosClientResponse> {
        return axios.post(path, data,this.getConfigForImage(params, authToken))
            .then(this.handleSuccess)
            .catch(this.handleErrors)
    }

    public delete(path: string, params: any, authToken: string, body?: any) : Promise<IDamAxiosClientResponse> {
        return axios.delete(path, this.getConfig(params,authToken, body))
            .then(this.handleSuccess)
            .catch(this.handleErrors)
    }

    public patch(path: string, params: any, authToken: string, data: any) : Promise<IDamAxiosClientResponse> {
        return axios.patch(path, data,this.getConfig(params, authToken))
            .then(this.handleSuccess)
            .catch(this.handleErrors)
    }

    public put(path: string, params: any, authToken: string, data: any) : Promise<IDamAxiosClientResponse> {
        return axios.put(path, data, this.getConfig(params, authToken))
            .then(this.handleSuccess)
            .catch(this.handleErrors)
    }

    public putToS3(path: string, data: any) : Promise<IDamAxiosClientResponse> {
        return axios.put(path, data)
            .then(this.handleSuccess)
            .catch(this.handleErrors)
    }

    private getConfig(params: any, authToken: string, body?: any): any {
        const authorization : string = "Bearer " + authToken

        if(body) {
            return {
                params: params,
                headers: {
                    Accept: "*/*",
                    Authorization: authorization,
                    "Content-Type": "application/json"
                },
                data: body
            }
        } else {
            return {
                params: params,
                headers: {
                    Accept: "*/*",
                    Authorization: authorization,
                    "Content-Type": "application/json"
                }
            }
        }
    }

    private getConfigForImage(params: any, authToken: string): any {
        const authorization : string = "Bearer " + authToken;
        return {
            params: params,
            headers: {
                Accept: "*/*",
                Authorization: authorization,
                "Content-Type": "application/json"
            }
        }
    }

    private handleSuccess(response : AxiosResponse) {
        return {data: response.data, status: apiStatus.success }
    }

    private handleErrors(error : AxiosError) : any {
        let message : string
        switch (error.response?.status){
            case 400:
                message = HTTP_STATUS_400_MESSAGE
                return { data: null, status: apiStatus.badRequest, damErrorCode: error.response?.status, damDefaultErrorMessage: message };
            case 401:
                message = HTTP_STATUS_401_MESSAGE
                return { data: null, status: apiStatus.unauthorized, damErrorCode: error.response?.status, damDefaultErrorMessage: message };
            case 403:
                message = HTTP_STATUS_403_MESSAGE
                return { data: null, status: apiStatus.forbidden, damErrorCode: error.response?.status, damDefaultErrorMessage: message };
            case 404:
                message = HTTP_STATUS_404_MESSAGE
                return { data: null, status: apiStatus.notFound, damErrorCode: error.response?.status, damDefaultErrorMessage: message };
            case 405:
                message = HTTP_STATUS_405_MESSAGE
                return { data: null, status: apiStatus.notAllowed, damErrorCode: error.response?.status, damDefaultErrorMessage: message };
            case 409:
                message = HTTP_STATUS_409_MESSAGE
                const errorResponse : AxiosResponse = error.response
                if (errorResponse.data?.message?.trim().length > 0) {
                    message = errorResponse.data.message
                } else if(errorResponse.data?.exceptionMessage?.trim().length > 0) {
                    message = errorResponse.data.exceptionMessage
                }
                return { data: errorResponse?.data, status: apiStatus.conflict, damErrorCode: error.response?.status, damDefaultErrorMessage: message};
            case 422:
                message = HTTP_STATUS_422_MESSAGE
                const errorResponse422: AxiosResponse = error.response
                return {data: errorResponse422?.data, status: apiStatus.unprocessableContent, damErrorCode: error.response?.status, damDefaultErrorMessage: message};
            case 500:
                message = HTTP_STATUS_500_MESSAGE
                return { data: null, status: apiStatus.error, damErrorCode: error.response?.status, damDefaultErrorMessage: message };
            default:
                console.error(`*** DamAxiosClient Error : ${JSON.stringify(error.response)}`);
                message = "An unknown error has been encountered. "
                return { data: null, status: apiStatus.error, damErrorCode: error.response?.status, damDefaultErrorMessage: message };
        }
    }
}

