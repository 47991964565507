import RootStore from "../root";
import {makeAutoObservable, runInAction} from "mobx";
import AuthStore from "../auth";
import CommonStore from "../common/common";
import BusinessUserStore from "../businessUser/businessUser";
import {User} from "../../types/acctMgmt/user";
import CommonUtils from "../../utils/commonUtils";
import {moveUsers} from "../../clients/uasApi";
import {apiStatus} from "../../utils/constants";
import React from "react";
import {getDealershipByCompCode} from "../../clients/damBackendApi";
import {BusinessUser} from "../../types/businessUser";
import { getChildrenByTopOwnerGroup, getOwnerLocations } from "../../clients/damBackendApi";
import {OwnerLocation} from "../../types/owner-group";
import { DAM_Super_Admin } from "src/types/groups";
import Validator from "../helpers/validator";
import { ERROR_MSG_COMP_CODE_SEARCH_CRITERIA } from "../search/messaging/errorMessagesSearch";
import AcctMgmtStore from "./acctMgmt";


export enum MoveUserSectionEnum {
    Initial,
    // Locations,
    Confirm
}

export enum MoveUserModeEnum {
    Single,
    Batch
}

export default class MoveUserStore {

    //Modal control
    isMoveModalVisible: boolean = false;
    moveUserSection: MoveUserSectionEnum = MoveUserSectionEnum.Initial;
    selectedLocation: string | undefined

    // Fields / Types
    moveUserMode: MoveUserModeEnum = MoveUserModeEnum.Single
    eligibleUsers: Array<User> = []
    selectedUsers: Array<User> = []
    selectedUser: User = {} as User
    compCodeErrorMessage: string = ""
    toBusinessUser: BusinessUser | null = null;
    topOwnerGroup: Array<OwnerLocation> = [];
    topOwnerGroupFilterValue: string = "";
    filteredTopOwnerGroup: Array<OwnerLocation> = [];
    toCompCode?: string | null = null 

    // Stores
    rootStore: RootStore;
    authStore: AuthStore;
    acctMgmtStore: AcctMgmtStore;
    commonStore: CommonStore;
    businessUserStore: BusinessUserStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.authStore = this.rootStore.authStore
        this.acctMgmtStore = this.rootStore.acctMgmtStore
        this.commonStore = this.rootStore.commonStore
        this.businessUserStore = this.rootStore.businessUserStore
        makeAutoObservable(this)
    };

    public openMoveUserModal = async (user?: User) => {
        if(!this.authStore.groups?.includes(DAM_Super_Admin)) {
            await this.getTopOwnerGroup();
        }
        this.isMoveModalVisible = true
        if(user){
            this.selectedUser = user;
            this.moveUserMode = MoveUserModeEnum.Single
        }else{
            this.moveUserMode = MoveUserModeEnum.Batch
        }
        this.moveUserSection = MoveUserSectionEnum.Initial
        
        this.initializeStore()
    }

    public initializeStore = () => {
        this.toCompCode = null
        this.selectedUser.toCompCode = null
        this.compCodeErrorMessage = ""
        this.toBusinessUser = null
        this.commonStore.clearPageMsg()
        this.commonStore.clearFeatureMsg()
    }

    public handleMoveUserCancel = () => {
        this.isMoveModalVisible = false
        this.selectedUser = {} as User
        this.selectedUsers.forEach(user => {
            user.selected = false
            user.toCompCode = null
        })
        this.selectedUsers = []
        this.resetTopOwnerGroupFields()
    }

    public handleBackButton = () => {
        switch (this.moveUserSection) {
            case MoveUserSectionEnum.Confirm: {
                this.moveUserSection = MoveUserSectionEnum.Initial
            }
        }
    }

    public handleNextButton = async () => {
        switch (this.moveUserSection) {
            case MoveUserSectionEnum.Initial: {
                await this.validateCompCode()
                if (!this.compCodeErrorMessage) {
                    runInAction(() => {
                        this.moveUserSection = MoveUserSectionEnum.Confirm
                    })
                }
            }
        }
    }

    public disableNextButton = () : boolean => {
        if(!this.isValidCompCode()) {
            return true
        }
        if(this.moveUserMode === MoveUserModeEnum.Single){
            return (this.selectedUser?.toCompCode === null || this.selectedUser?.toCompCode === "")
        }
        else if(this.moveUserMode === MoveUserModeEnum.Batch){
            return (this.selectedUsers.findIndex((user => user?.toCompCode == null || user?.toCompCode === "")) >= 0)
        }
        return true
    }

    public handleCompCodeEntry = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.toCompCode = event.target.value
        this.compCodeErrorMessage = Validator.containsSpecialCharacters(this.toCompCode) ? ERROR_MSG_COMP_CODE_SEARCH_CRITERIA : ""
        
        switch(this.moveUserMode){
            case MoveUserModeEnum.Single: {
                this.selectedUser.toCompCode = event.target.value
                break
                }
            case MoveUserModeEnum.Batch: {
                this.selectedUsers.forEach(user => {
                    user.toCompCode = event.target.value
                })
                break
            }
        }
    }

    public handleMoveButton = async () => {
        this.isMoveModalVisible = false
        await this.moveUser()
        await this.rootStore.acctMgmtStore.getUsersByCompCode()
    }

    public handleMoveUsersButton = async () => {
        this.isMoveModalVisible = false
        await this.moveUsers()
        await this.rootStore.acctMgmtStore.getUsersByCompCode()
    }

    public isValidCompCode = (): boolean => {
        return (!this.compCodeErrorMessage)
    }

    public isTopOwnerGroupChecked = () => {
        return (this.selectedUser?.toCompCode != null)
    }

    public validateCompCode = async () => {
        this.rootStore.commonStore.processingApiCall(true);

        const compCode = this.toCompCode
        this.compCodeErrorMessage = ""
        this.toBusinessUser = null
        if(this.moveUserMode === MoveUserModeEnum.Single){
            if (compCode === this.selectedUser?.compCode?.trim().toUpperCase()) {
                this.setCompCodeError("User is already at this comp code.")
            }
        }else if (this.moveUserMode === MoveUserModeEnum.Batch){
            this.selectedUsers.forEach(user => {
                if(compCode === user?.compCode?.trim().toUpperCase()){
                    this.setCompCodeError("User(s) are already at this comp code.")
                }
            })
        }

        if (compCode) {
            await getDealershipByCompCode(this.authStore.token, compCode).then((resp) => {
                switch(resp?.status){
                    case apiStatus.success :
                        this.setToBusinessUser(resp.data)
                        break;
                    case apiStatus.notFound :
                        CommonUtils.displayConsoleLogError(`*** comp code ${compCode} not found. Response: ` + JSON.stringify(resp));
                        this.setCompCodeError("This comp code could not be found.")
                        break;
                    default :
                        this.setCompCodeError(resp.damDefaultErrorMessage)
                        CommonUtils.displayConsoleLogError(`*** Error calling BIS for comp code ${compCode}. Response: ` + JSON.stringify(resp));
                        break;
                }
            });
        }

        this.rootStore.commonStore.processingApiCall(false);
    }

    private setCompCodeError = (msg: string) => {
        this.compCodeErrorMessage = msg
    }

    private setToBusinessUser = (toBusinessUser: any) => {
        this.toBusinessUser = toBusinessUser
    }

    public moveUser = async () => {
        this.rootStore.commonStore.processingApiCall(true);
        CommonUtils.logNewRelicPageAction("Dealer Account Management", {userAction: 'Move User', compCode: this.selectedUser.compCode, toCompCode: this.toCompCode})

        let userIdArray: any = []
        userIdArray.push(this.selectedUser.userId)

        await moveUsers(this.rootStore.authStore.token, userIdArray, this.toCompCode!!, this.authStore.user?.email!).then((resp: any) => {
            switch (resp?.status) {
                case apiStatus.success :
                    this.rootStore.commonStore.clearPageMsg();
                    const msg = `Success! You have moved ${this.selectedUser.firstName} ${this.selectedUser.lastName} to ${this.toBusinessUser?.companyName} - ${this.toBusinessUser?.compCode}`
                    this.rootStore.commonStore.displayFeatureNotification(msg, "green");
                    break;
                case apiStatus.forbidden :
                    this.rootStore.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    break;
                case apiStatus.conflict :
                    this.rootStore.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "red")
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling UAS to move user to comp code ${this.toCompCode}.  Response: ` + JSON.stringify(resp));
                    this.rootStore.commonStore.displayPageNotification(`Severe error occurred trying to move user to comp code '${this.toCompCode}'.`, "red")
                    break;
            }
        });
        this.rootStore.commonStore.processingApiCall(false);
        this.resetTopOwnerGroupFields();
    };

    public moveUsers = async () => {
        this.rootStore.commonStore.processingApiCall(true);
        CommonUtils.logNewRelicPageAction("Dealer Account Management", {userAction: 'Move User Batch', numberOfSelectedUsers: this.selectedUsers.length, toCompCode: this.toCompCode})

        this.eligibleUsers = this.selectedUsers.filter(user=> !this.rootStore.acctMgmtStore.isUserInvitedOrReinvited(user));

        let userIdArray: any = []
        this.eligibleUsers.forEach(user => {
            userIdArray.push(user.userId)
        })

        await moveUsers(this.rootStore.authStore.token, userIdArray, this.toCompCode!!, this.authStore.user?.email!).then((resp: any) => {
            switch (resp?.status) {
                case apiStatus.success :
                    this.rootStore.commonStore.clearPageMsg();
                    const msg = `Success! You have moved ${this.eligibleUsers.length} Users to ${this.toBusinessUser?.companyName} - ${this.toBusinessUser?.compCode}`
                    this.rootStore.commonStore.displayFeatureNotification(msg, "green");
                    break;
                case apiStatus.forbidden :
                    this.rootStore.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    break;
                case apiStatus.conflict :
                    this.rootStore.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "red")
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling UAS to move ${this.eligibleUsers.length} users to comp code ${this.toCompCode}.  Response: ` + JSON.stringify(resp));
                    this.rootStore.commonStore.displayPageNotification(`Severe error occurred trying to move ${this.eligibleUsers.length} users to comp code '${this.toCompCode}'.`, "red")
                    break;
            }
        });
        this.rootStore.commonStore.processingApiCall(false);
        this.resetTopOwnerGroupFields();
        this.selectedUsers = []
        this.eligibleUsers = []
    };

    private resetTopOwnerGroupFields = () => {
        this.topOwnerGroup = [];
        this.topOwnerGroupFilterValue = "";
        this.filteredTopOwnerGroup = [];
    }

    public selectLocation(locNumber:string){
        alert(locNumber)
        this.selectedLocation = locNumber
    }

    public getTopOwnerGroup = async () => {
        let compCode = this.rootStore.acctMgmtStore.compCode;

        let rootSalesforceId = this.businessUserStore.businessUser?.rootSalesforceId;
        let parentSalesforceId = this.businessUserStore.businessUser?.parentSalesforceId;

        this.rootStore.commonStore.processingApiCall(true);
        if(rootSalesforceId != null) {
            await getChildrenByTopOwnerGroup(this.rootStore.authStore.token, rootSalesforceId).then((resp: any) => {
                switch(resp?.status) {
                    case apiStatus.success :
                        runInAction(() => {
                            this.topOwnerGroup = resp.data.businessUsers.map((businessUser: BusinessUser) => {
                                return {
                                    status: businessUser.active ? "Active" : "Inactive",
                                    address: businessUser.address,
                                    companyName: businessUser.companyName,
                                    compCode: businessUser.compCode,
                                }
                            }).filter((loc: OwnerLocation) => {
                                return loc.compCode !== compCode
                            });
                            this.filteredTopOwnerGroup = this.topOwnerGroup;
                        });
                    break
                    case apiStatus.notFound :
                        break;
                    case apiStatus.forbidden :
                        this.rootStore.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                        break;
                    default :
                        CommonUtils.displayConsoleLogError(`*** Error calling BIS to get top owner locations for rootSalesforceId ${rootSalesforceId}.  Response: ` + JSON.stringify(resp));
                        this.rootStore.commonStore.displayPageNotification(`Error occurred trying to get owner locations for comp code '${compCode}'.`, "red")
                        break;
                };
            });
        }
        else {
            await getOwnerLocations(this.rootStore.authStore.token, parentSalesforceId).then((resp: any) => {
                switch(resp?.status) {
                    case apiStatus.success :
                        runInAction(() => {
                            this.topOwnerGroup = resp.data.businessUsers.map((businessUser: BusinessUser) => {
                                return {
                                    status: businessUser.active ? "Active" : "Inactive",
                                    address: businessUser.address,
                                    companyName: businessUser.companyName,
                                    compCode: businessUser.compCode,
                                }
                            }).filter((loc: OwnerLocation) => {
                                return loc.compCode !== compCode
                            });
                            this.filteredTopOwnerGroup = this.topOwnerGroup;
                        });
                    break
                    case apiStatus.notFound :
                        break;
                    case apiStatus.forbidden :
                        this.rootStore.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                        break;
                    default :
                        CommonUtils.displayConsoleLogError(`*** Error calling BIS to get top owner locations for parentSalesforceId ${parentSalesforceId}.  Response: ` + JSON.stringify(resp));
                        this.rootStore.commonStore.displayPageNotification(`Error occurred trying to get owner locations for comp code '${compCode}'.`, "red")
                        break;
                };
            });
        }
        this.rootStore.commonStore.processingApiCall(false);
    }

    public onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.topOwnerGroupFilterValue = event.target.value
        this.searchTopOwnerGroup()
    }

    public searchTopOwnerGroup = () => {
        if (this.topOwnerGroupFilterValue.length > 0) {
            this.filteredTopOwnerGroup = this.topOwnerGroup.filter((loc) => {
                return (
                    loc.companyName.toLowerCase().includes(this.topOwnerGroupFilterValue.toLowerCase())
                    || loc.compCode.toLowerCase().includes(this.topOwnerGroupFilterValue.toLowerCase()))
            });
        } else {
            this.filteredTopOwnerGroup = this.topOwnerGroup
        }
    }

    public handleTopOwnerGroupSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.toCompCode = event.target.value;
        if(this.moveUserMode === MoveUserModeEnum.Single){
            this.selectedUser.toCompCode = event.target.value
        }
        if(this.moveUserMode === MoveUserModeEnum.Batch){
            this.selectedUsers.forEach(user => {
                user.toCompCode = event.target.value
            })
        }
    }

    public hasUsersSelected = () => {
        return this.selectedUsers.length > 0
    }

    public hasSelectedUsersEligibleToMove = () : Boolean  => {
        return this.selectedUsers.filter(function (user) {
            return (user.status === 'Active' || user.status === "Inactive");
        }).length > 0
    };

    public hasSelectedIneligibleUsersToMove = () : Boolean => {
        return this.selectedUsers.filter((user) => {
            return this.rootStore.acctMgmtStore.isUserInvitedOrReinvited(user);
        }).length > 0
    };

    public handleUserSelectAll = () => {
        const allSelected: boolean = this.isAllSelected()
        this.selectedUsers = []

        this.rootStore.acctMgmtStore.userRecords.forEach((userRecord) => {
            if (allSelected) {
                userRecord.selected = false
            } else {
                userRecord.selected = true
                this.selectedUsers.push(userRecord)
            }
        })
    }

    public isAllSelected = () => {
        return !(this.rootStore.acctMgmtStore.userRecords.length === 0) && !(this.rootStore.acctMgmtStore.userRecords.some((record) => {
            return !record.selected
        }))
    }

    public handleUserSelect = (userRecord: User) => {
        if (userRecord.selected) {
            userRecord.selected = false
            this.selectedUsers.splice(this.selectedUsers.indexOf(userRecord), 1)
        } else {
            userRecord.selected = true
            this.selectedUsers.push(userRecord)
        }
        this.hasSelectedUsersEligibleToMove()
    }

    public isMultipleSelected = (userId: number) => {
        if(this.selectedUsers.length > 1) {
            return true;
        }
        else if(this.selectedUsers.length === 1) {
            return (this.selectedUsers[0].userId !== userId)
        }
        else {
            return false;
        }
    }
}