import AuthStore from "../auth";
import BusinessUserStore from "../businessUser/businessUser";
import CommonStore from "../common/common";
import CommonUtils from "../../utils/commonUtils";
import RootStore from "../root";
import {apiStatus} from "../../utils/constants";
import {IComplexOption} from "@carfax-stencils/types";
import {getUserAuditHistory} from "../../clients/uasApi";
import {makeAutoObservable, runInAction} from "mobx";
import {User} from "../../types/acctMgmt/user";
import {UserAuditHistoryRecord} from "../../types/acctMgmt/userAuditHistoryRecord";

export default class UserAuditHistoryStore {

    // Fields / Types
    compCode: string = "";
    token: string = "";
    userAuditFields: Array<string> = new Array<string>();
    userAuditFieldsSelectedValue: string = "";
    userAuditUsers: Array<string> = new Array<string>();
    userAuditUsersSelectedValue: string = "";
    selectedUser: User | undefined = undefined;
    selectedUserAuditHistoryRecords: Array<UserAuditHistoryRecord> = new Array<UserAuditHistoryRecord>();
    userAuditHistoryRecords: Array<UserAuditHistoryRecord> = new Array<UserAuditHistoryRecord>();
    paginatedUserAuditHistoryRecords: Array<UserAuditHistoryRecord> = new Array<UserAuditHistoryRecord>();
    isUserAuditHistoryModalVisible: boolean = false;
    paginationCurrentPage: number = 1;
    paginationPerPageValue: number = 50;

    // Stores
    rootStore: RootStore;
    authStore: AuthStore;
    businessUserStore: BusinessUserStore;
    commonStore: CommonStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.authStore = this.rootStore.authStore;
        this.businessUserStore = this.rootStore.businessUserStore;
        this.commonStore = this.rootStore.commonStore;
        makeAutoObservable(this);
    };

    public initializeUserAuditHistoryModal() {
        this.userAuditFieldsSelectedValue = "";
        this.userAuditUsersSelectedValue = "";
        this.userAuditUsers = new Array<string>();
        this.userAuditFields = new Array<string>();
        this.userAuditHistoryRecords = new Array<UserAuditHistoryRecord>();
        this.selectedUserAuditHistoryRecords = new Array<UserAuditHistoryRecord>();
        this.paginatedUserAuditHistoryRecords = new Array<UserAuditHistoryRecord>();
        this.paginationCurrentPage = 1;
        this.paginationPerPageValue= 50;
    }

    public getUserAuditHistoryRecords = async (token: string, userId: number) => {

        CommonUtils.logNewRelicPageAction("Dealer Account Management", {userAction: 'Get User Audit History', compCode: this.compCode})

        this.commonStore.processingApiCall(true);

        await getUserAuditHistory(token, userId).then((resp: any) => {
            switch(resp?.status){
                case apiStatus.success :
                    runInAction(() => {
                        this.userAuditFields = resp.data.fields;
                        this.userAuditUsers = resp.data.users;
                        this.userAuditHistoryRecords = resp.data.data;
                        this.selectedUserAuditHistoryRecords = resp.data.data;
                        this.updateUserAuditHistoryPaginatedRecords(0,this.paginationPerPageValue)
                        this.commonStore.processingApiCall(false);
                    });
                    break;
                case apiStatus.forbidden :
                    this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    this.commonStore.processingApiCall(false);
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling UAS to retrieve user audit history records for user ${this.selectedUser?.firstName} ${this.selectedUser?.firstName}. Userid = ${this.selectedUser?.userId} .  Response: ` + JSON.stringify(resp));
                    this.commonStore.displayPageNotification(`Severe error occurred trying to retrieve user audit history records for ${this.selectedUser?.firstName} ${this.selectedUser?.firstName}.`, "red")
                    this.commonStore.processingApiCall(false);
                    break;
            };
        });
    };

    public displayUserAuditHistoryModal = async (selectedUser: User) => {
        this.isUserAuditHistoryModalVisible = true;
        this.selectedUser = selectedUser;
        this.initializeUserAuditHistoryModal();
        this.commonStore.processingApiCall(true);
        await this.getUserAuditHistoryRecords(this.authStore.token, this.selectedUser?.userId!)
        this.commonStore.processingApiCall(false);
    };

    public closeUserAuditHistoryModal = () => {
        this.isUserAuditHistoryModalVisible = false;
        this.initializeUserAuditHistoryModal();
    };

    public handleUserAuditHistoryPaginationChange = (data: any) => {
        CommonUtils.logNewRelicPageAction("Account Mgmt User Audit History", {userAction: 'Paginate Through User Audit Records'})

        this.paginationCurrentPage = data.currentPage;
        this.paginationPerPageValue = data.perPageValue;

        let currentPage = this.paginationCurrentPage;
        let perPage = this.paginationPerPageValue;
        let offset = (currentPage - 1) * perPage;

        this.updateUserAuditHistoryPaginatedRecords(offset, offset + perPage)
    }

    public onFieldNameChange = (changedValue: string) => {
        this.userAuditFieldsSelectedValue = changedValue
        this.filterAuditRecords()
    }

    public onFieldNameSelected = (selectedValue: IComplexOption | undefined) => {
        this.userAuditFieldsSelectedValue = selectedValue?.value!
        this.filterAuditRecords()
    }

    public onUserChange = (changedValue: string | undefined) => {
        this.userAuditUsersSelectedValue = changedValue!
        this.filterAuditRecords()
    }

    public onUserSelected = (selectedValue: IComplexOption | undefined) => {
        this.userAuditUsersSelectedValue = selectedValue?.value!
        this.filterAuditRecords()
    }

    private filterAuditRecords() {
        if(this.userAuditFieldsSelectedValue && this.userAuditUsersSelectedValue) {
            this.selectedUserAuditHistoryRecords = this.userAuditHistoryRecords.filter( userAuditHistoryRecord => userAuditHistoryRecord.type === this.userAuditFieldsSelectedValue &&
                                                                                        userAuditHistoryRecord.modifiedBy === this.userAuditUsersSelectedValue )
        } else {
            if(this.userAuditFieldsSelectedValue) {
                this.selectedUserAuditHistoryRecords = this.userAuditHistoryRecords.filter( userAuditHistoryRecord => userAuditHistoryRecord.type === this.userAuditFieldsSelectedValue )
            } else {
                if(this.userAuditUsersSelectedValue) {
                    this.selectedUserAuditHistoryRecords = this.userAuditHistoryRecords.filter( userAuditHistoryRecord => userAuditHistoryRecord.modifiedBy === this.userAuditUsersSelectedValue )
                } else {
                    this.selectedUserAuditHistoryRecords = this.userAuditHistoryRecords
                }
            }
        }

        this.paginationCurrentPage = 1;
        this.updateUserAuditHistoryPaginatedRecords(0,this.paginationPerPageValue)
    }

    protected updateUserAuditHistoryPaginatedRecords(start: number, end: number) {
        runInAction(() => {
            this.paginatedUserAuditHistoryRecords = this.selectedUserAuditHistoryRecords.slice(start, end);
            this.transformFieldValues()
        });
    };

    public transformFieldValues(){
        this.paginatedUserAuditHistoryRecords.forEach( (record: UserAuditHistoryRecord) => {
            record.date = CommonUtils.convertEpochDateToMMDDYYYY(CommonUtils.convertDateToEpoch(record.date))
        })
    }
}